/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowRightSLine, RiCheckboxCircleLine, RiAlertLine, RiFileTextLine, RiDownloadLine } from "react-icons/ri"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const HRCompliancePage = () => {
  const complianceAreas = [
    {
      id: 'employment-law',
      title: 'Employment Law',
      items: [
        {
          text: 'Fair Labor Standards Act (FLSA)',
          url: 'https://www.dol.gov/agencies/whd/flsa'
        },
        {
          text: 'Equal Employment Opportunity (EEO)',
          url: 'https://www.eeoc.gov/employers'
        },
        {
          text: 'Americans with Disabilities Act (ADA)',
          url: 'https://www.ada.gov/employment.html'
        },
        {
          text: 'Family and Medical Leave Act (FMLA)',
          url: 'https://www.dol.gov/agencies/whd/fmla'
        }
      ]
    },
    {
      id: 'workplace-safety',
      title: 'Workplace Safety',
      items: [
        {
          text: 'OSHA Regulations',
          url: 'https://www.osha.gov/laws-regs'
        },
        {
          text: 'Emergency Response Plans',
          url: 'https://www.osha.gov/emergency-preparedness'
        },
        {
          text: 'Safety Training Requirements',
          url: 'https://www.osha.gov/training'
        },
        {
          text: 'Incident Reporting Procedures',
          url: 'https://www.osha.gov/report.html'
        }
      ]
    }
  ]

  const bestPractices = [
    {
      id: 'audits',
      title: 'Regular Audits',
      description: 'Conduct periodic compliance audits to identify and address potential issues before they become problems.'
    },
    {
      id: 'documentation',
      title: 'Documentation',
      description: 'Maintain detailed records of all HR policies, procedures, and employee interactions.'
    },
    {
      id: 'training',
      title: 'Training',
      description: 'Provide regular compliance training for managers and employees to ensure understanding of requirements.'
    }
  ]

  const commonChallenges = [
    {
      id: 'regulatory-changes',
      title: 'Keeping Up with Regulatory Changes',
      description: 'Employment laws and regulations frequently change at federal, state, and local levels.',
      solutions: [
        'Subscribe to regulatory updates',
        'Partner with legal counsel',
        'Attend compliance workshops',
        'Use compliance tracking software'
      ]
    },
    {
      id: 'documentation-management',
      title: 'Documentation Management',
      description: 'Maintaining comprehensive and accurate records while ensuring data privacy and security.',
      solutions: [
        'Implement digital document management',
        'Create standardized forms',
        'Regular audit trails',
        'Secure storage solutions'
      ]
    },
    {
      id: 'training-engagement',
      title: 'Training and Engagement',
      description: 'Ensuring all employees complete required training and understand compliance requirements.',
      solutions: [
        'Interactive training programs',
        'Mobile-friendly learning platforms',
        'Regular refresher courses',
        'Compliance certification tracking'
      ]
    }
  ]

  return (
  <Layout className="hr-compliance-page">
    <SEO 
      title="HR Compliance Guide & Best Practices | Performance Reviews" 
      description="Comprehensive guide to HR compliance requirements, best practices, and implementation strategies. Learn how to maintain compliance while building an effective HR program."
      keywords={[
        "HR compliance guide",
        "HR compliance requirements",
        "HR legal requirements",
        "HR compliance best practices",
        "workplace compliance",
        "HR policy compliance",
        "employment law compliance",
        "HR compliance checklist",
        "compliance management",
        "HR regulations"
      ]}
    />
    
    {/* Hero Section */}
    <div sx={{
      bg: 'primaryMuted',
      pt: [3, 4],
      pb: [3, 4]
    }}>
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3
      }}>
        <h1 sx={{
          fontSize: ['2rem', '2.5rem'],
          fontWeight: 700,
          textAlign: 'center',
          mb: 2,
          color: 'primary'
        }}>
          HR Compliance Guide
        </h1>
        
        <p sx={{
          fontSize: '1.1rem',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          mb: 3,
          lineHeight: 1.5,
          color: 'text'
        }}>
          Stay compliant and protect your organization with our comprehensive guide to HR compliance. 
          Learn about key requirements, best practices, and implementation strategies for building a 
          strong compliance program.
        </p>
      </div>
    </div>

    {/* Main Content */}
    <div sx={{ maxWidth: '1200px', mx: 'auto', px: 3, py: [3, 4] }}>
      {/* Key Areas Section */}
      <section sx={{ mb: [4, 5] }}>
        <h2 sx={{ 
          fontSize: ['1.75rem', '2rem'],
          fontWeight: 600,
          mb: 3,
          color: 'primary'
        }}>
          Key Areas of HR Compliance
        </h2>
        
        <div sx={{ 
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          gap: 3,
          mb: 4
        }}>
          {complianceAreas.map(area => (
            <div key={area.id} sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 15px rgba(0,0,0,0.15)'
              }
            }}>
              <h3 sx={{ fontSize: '1.4rem', mb: 3, color: 'primary' }}>{area.title}</h3>
              <ul sx={{ 
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {area.items.map(item => (
                  <li key={item.text} sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <RiCheckboxCircleLine sx={{ color: 'primary', mr: 2, flexShrink: 0 }} />
                    <a 
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: 'primary',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Implementation Strategy Section */}
        <div sx={{
          bg: 'white',
          p: 3,
          borderRadius: '8px',
          mb: 4,
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h3 sx={{ 
            fontSize: ['1.3rem', '1.4rem'],
            mb: 3,
            color: 'primary'
          }}>
            Implementation Strategy
          </h3>
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: 4
          }}>
            <div>
              <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Initial Setup</h4>
              <ol sx={{ pl: 4, mb: 0 }}>
                <li sx={{ mb: 1 }}>Assess current compliance status</li>
                <li sx={{ mb: 1 }}>Identify gaps and risks</li>
                <li sx={{ mb: 1 }}>Develop compliance policies</li>
                <li sx={{ mb: 1 }}>Create implementation timeline</li>
              </ol>
            </div>
            <div>
              <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Ongoing Management</h4>
              <ol sx={{ pl: 4, mb: 0 }}>
                <li sx={{ mb: 1 }}>Regular policy reviews</li>
                <li sx={{ mb: 1 }}>Employee training programs</li>
                <li sx={{ mb: 1 }}>Compliance monitoring</li>
                <li sx={{ mb: 1 }}>Documentation updates</li>
              </ol>
            </div>
          </div>
        </div>

        {/* Common Challenges Section */}
        <h3 sx={{ 
          fontSize: ['1.3rem', '1.4rem'],
          mb: 3,
          color: 'primary'
        }}>
          Common Compliance Challenges & Solutions
        </h3>
        <div sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr'],
          gap: 3,
          mb: 4
        }}>
          {commonChallenges.map(challenge => (
            <div key={challenge.id} sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
            }}>
              <h4 sx={{ 
                fontSize: '1.2rem',
                mb: 2,
                color: 'primary',
                display: 'flex',
                alignItems: 'center'
              }}>
                <RiAlertLine sx={{ mr: 2 }} />
                {challenge.title}
              </h4>
              <p sx={{ mb: 2 }}>{challenge.description}</p>
              <h5 sx={{ fontSize: '1.1rem', mb: 2, color: 'primary' }}>Solutions:</h5>
              <ul sx={{ pl: 4 }}>
                {challenge.solutions.map(solution => (
                  <li key={solution} sx={{ mb: 1 }}>{solution}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Resources Section */}
        <h3 sx={{ 
          fontSize: ['1.3rem', '1.4rem'],
          mb: 3,
          color: 'primary'
        }}>
          Compliance Resources
        </h3>
        <div sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr'],
          gap: 3,
          mb: 4
        }}>
          <Link to="/resources/compliance-checklist" sx={{
            bg: 'white',
            p: 4,
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            textDecoration: 'none',
            color: 'text',
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'translateY(-4px)'
            }
          }}>
            <RiFileTextLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
            <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Compliance Checklist</h4>
            <p sx={{ mb: 0 }}>Download our comprehensive HR compliance checklist</p>
          </Link>
          
          <Link to="/policies" sx={{
            bg: 'white',
            p: 4,
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            textDecoration: 'none',
            color: 'text',
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'translateY(-4px)'
            }
          }}>
            <RiDownloadLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
            <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Policy Templates</h4>
            <p sx={{ mb: 0 }}>Access customizable HR policy templates</p>
          </Link>
          
          <Link
            to="/guides"
            sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              textDecoration: 'none',
              color: 'text',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-4px)'
              }
            }}>
            <RiFileTextLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
            <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Training Materials</h4>
            <p sx={{ mb: 0 }}>Browse our library of compliance training resources</p>
          </Link>
        </div>

        {/* CTA Section */}
        <div sx={{
          bg: 'primaryMuted',
          p: 3,
          borderRadius: '8px',
          textAlign: 'center',
          mt: 4
        }}>
          <h3 sx={{ 
            fontSize: ['1.2rem', '1.3rem'],
            mb: 2,
            color: 'primary'
          }}>
            Need Help with HR Compliance?
          </h3>
          <p sx={{ mb: 3, fontSize: '1.1rem' }}>
            Our team of HR experts can help you develop and implement a comprehensive compliance program.
          </p>
          <Link to="/contact" sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 2,
            bg: 'primary',
            color: 'white',
            py: 2,
            px: 4,
            borderRadius: '6px',
            fontSize: '1.1rem',
            fontWeight: 600,
            textDecoration: 'none',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              color: 'white'
            }
          }}>
            Contact Our HR Consultants <RiArrowRightSLine />
          </Link>
        </div>
      </section>

      {/* FAQ Section - moved inside the section tag */}
      <section sx={{ mb: [4, 5] }}>
        <h2 sx={{ 
          fontSize: ['1.5rem', '1.65rem'],
          mb: 4,
          color: 'primary',
          textAlign: 'center'
        }}>Frequently Asked Questions About HR Compliance</h2>

        <div sx={{
          display: 'grid',
          gap: 4,
          maxWidth: '800px',
          mx: 'auto'
        }}>
          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What is HR compliance and why is it so important?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>HR compliance involves adhering to labor laws and regulations governing the employer-employee relationship. It's crucial for avoiding legal penalties (fines, lawsuits), protecting the company's reputation, ensuring fair treatment of employees, and creating a safe and ethical work environment.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What are the biggest risks of non-compliance?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Non-compliance can lead to significant financial penalties, costly legal battles, damage to brand reputation, decreased employee morale, difficulty attracting talent, and even operational disruptions. It's a major risk for businesses of all sizes.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How do compliance requirements differ by location (state/country)?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Compliance laws vary significantly based on federal, state, and local jurisdictions. Requirements for minimum wage, overtime, leave policies, anti-discrimination laws, and safety regulations can all differ. Businesses operating in multiple locations must comply with the laws in each area.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How can small businesses manage HR compliance effectively?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Small businesses can manage compliance by staying informed about relevant laws, using reliable policy templates (reviewed by legal counsel), maintaining thorough documentation, providing basic training, and considering partnerships with HR consultants or PEOs (Professional Employer Organizations) for expert support.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What role does technology play in managing HR compliance?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>HR technology (like HRIS and compliance software) can significantly help by automating record-keeping, tracking training completion, managing policy updates and acknowledgments, ensuring accurate timekeeping and payroll, and providing reporting for audits. It helps streamline processes and reduce manual errors.</p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
  )
}

export default HRCompliancePage 